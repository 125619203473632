/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Skeleton } from '@mui/material'
import { CardGrid, Loader } from '@tumelo/shared'
import { Column, Grid } from '@tumelo/designsystem'
import { useAppSelector } from '../../../application/store'
import { TopHoldingsContainer } from '../../TopHoldings'
import { PageHeader } from '../../PageHeader'
import { DashboardVotesOpenContainer } from '../../DashboardVotes'
import { ReturningCardsContainer } from '../../ReturningCards/ReturningCardsContainer'
import { isIntermediaryState, isValueState, Payload } from '../../../application/payload'
import { useHabitatVotingPolicies, useVotingPolicySelection } from '../../../application/features/votingPolicies/hooks'
import { useAccount } from '../../../application/features/account/hooks'
import { VotingPoliciesBanner } from '../../VotingPoliciesBanner'
import { useInvestor } from '../../../application/features/investor/useInvestor'
import { selectSoftConfig } from '../../../application/features/config/selectors'
import { VotePoliciesModal } from '../../VotingPoliciesModal'

interface Props {
  handleFundClick: () => void
  handleCompanyClick: (id: string) => void
  handleOpenVotes: () => void
  name: string | null
}

export const Dashboard: React.VFC<Props> = ({ handleFundClick, handleCompanyClick, handleOpenVotes, name }) => {
  const { votingPoliciesEnabled } = useAppSelector((state) => ({
    habitat: state.config.config.habitat,
    investorId: Payload.toMaybe(state.investor.investor)?.investorId,
    votingPoliciesEnabled: selectSoftConfig(state).featureSwitches.enableVotingPolicies,
  }))
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const votingPolicySelection = useVotingPolicySelection(account)
  const habitatVotingPolicies = useHabitatVotingPolicies()

  const habitatVotingPoliciesFetched =
    isValueState(habitatVotingPolicies) && habitatVotingPolicies !== undefined && habitatVotingPolicies.length > 0

  if (isIntermediaryState(habitatVotingPolicies)) {
    return (
      <StyledDiv>
        <Loader />
      </StyledDiv>
    )
  }

  return (
    <>
      <PageHeader title={<span data-hj-suppress>Hello {name != null ? name : <StyledSkeleton />}</span>} />
      <StyledGrid>
        <Column xs={12} sm={12} md={12} lg={12}>
          {votingPoliciesEnabled && habitatVotingPoliciesFetched && (
            <VotePoliciesModal votingPolicies={habitatVotingPolicies} />
          )}
          {votingPoliciesEnabled &&
            habitatVotingPoliciesFetched &&
            (!isValueState(votingPolicySelection) || votingPolicySelection === undefined) && <VotingPoliciesBanner />}
        </Column>
        <Column xs={12} sm={12} md={6} lg={6}>
          <TopHoldingsContainer onClickCta={handleFundClick} onCompanyClick={handleCompanyClick} />
        </Column>
        <Column xs={12} sm={12} md={6} lg={6}>
          <DashboardVotesOpenContainer handleCallToAction={handleOpenVotes} />
        </Column>
      </StyledGrid>
      <CardGrid>
        <ReturningCardsContainer />
      </CardGrid>
    </>
  )
}

const StyledSkeleton = styled(Skeleton)`
  display: inline-block !important;
  width: 6rem;
`

const StyledGrid = styled(Grid)`
  height: auto;
`

const StyledDiv = styled.div`
  height: 100vh;
  max-height: 100vh;
`
