/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { isPollOpen, Poll, Ballot, InvestorVoteResponse } from '@tumelo/shared'
import { PollOpen } from './components/PollOpen'
import { PollClosed } from './components/PollClosed'
import { PollCompleted } from './components/PollCompleted'

interface Props {
  poll: Poll
  ballot: Ballot | undefined
  onClickOpenVotes: () => void
  onClickAnswer: (position: InvestorVoteResponse) => void
}

export const PollVote: React.VFC<Props> = ({ poll, ballot, onClickAnswer, onClickOpenVotes }) => {
  if (ballot && ballot.investorVote) {
    return <PollCompleted ballot={ballot} poll={poll} onClick={onClickOpenVotes} />
  }

  if (!isPollOpen(poll)) {
    return <PollClosed poll={poll} onClick={onClickOpenVotes} />
  }

  return <PollOpen onClickAnswer={onClickAnswer} poll={poll} />
}
