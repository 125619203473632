/* eslint-disable @typescript-eslint/no-deprecated */

import React, { ReactNode, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { ApplauseIcon, SubHeading, Body } from '@tumelo/shared'
import { ArrowDownIcon } from '@tumelo/designsystem'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { PollCardlet } from '../Cardlet/PollCardlet'

interface Props {
  polls: BallotWithPollAndOrg[]
  title: string | ReactNode
  subheading?: string | ReactNode
}

export const AwaitingResultsList: React.VFC<Props> = ({ polls, title, subheading }) => {
  const [showMore, setShowMore] = useState(false)
  const { colors } = useTheme()
  const toggleShowMore = () => setShowMore(!showMore)
  const pollsToDisplay = showMore ? polls : polls.slice(0, 3)

  return (
    <Container>
      <TopBorder />
      <TitleContainer>
        <IconContainer>
          <ApplauseIcon height={26} width={26} fill={colors.primary.medium} />
        </IconContainer>
        <SubHeading m={0}>{title}</SubHeading>
      </TitleContainer>
      <Body as="p">{subheading}</Body>
      <PollGrid>
        {pollsToDisplay.map((poll) => (
          <PollCardlet key={poll.poll.id} poll={poll} navigateTo="openVote" />
        ))}
      </PollGrid>
      {polls.length > 3 && (
        <ButtonContainer>
          <ShowMoreButton type="button" onClick={toggleShowMore}>
            <Chevron active={showMore} />
            <Body as="span" color={colors.grey.dark}>
              {showMore ? 'Show me fewer' : 'Show me more'}
            </Body>
          </ShowMoreButton>
        </ButtonContainer>
      )}
    </Container>
  )
}

const TopBorder = styled.div`
  border-top: ${({ theme }) => `2px solid ${theme.colors.grey.light}`};
  margin: 0 0 2rem -3.6rem;
`

const Container = styled.div`
  margin: 0 0 3rem;
`

const ButtonContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 2rem;
`

const ShowMoreButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Chevron = styled(ArrowDownIcon)<{ active: boolean }>`
  transform: ${(p) => p.active && 'rotate(180deg);'};
  fill: ${({ theme }) => theme.colors.grey.dark};
  overflow: hidden;
`

const PollGrid = styled.div`
  --gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
  > * {
    margin: var(--gap) 0 0 var(--gap);
  }
  padding-top: 0.6rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
`

const IconContainer = styled.div`
  display: inline-flex;
  padding-right: 1.5rem;
`
