/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { useInvestorOpenVotesCloseToExpiration } from '../../application/features/featuredVotes/hooks'
import { DashboardVotesOpen } from './DashboardVotesOpen'

interface Props {
  handleCallToAction: () => void
}

export const DashboardVotesOpenContainer: React.VFC<Props> = ({ handleCallToAction }) => {
  const votes = useInvestorOpenVotesCloseToExpiration()

  return <DashboardVotesOpen votes={votes} handleCallToAction={handleCallToAction} />
}
